document.addEventListener("DOMContentLoaded", () => {

	const mainContent = document.querySelector(".main-content");
	if (mainContent) {
		const p = mainContent.querySelector(".title-container p");

		function calculateMainContentHeight () {

			if (window.innerWidth < 1240) {
				mainContent.style.height = `${window.innerHeight + p.offsetHeight + 35}px`;
			} else {
				mainContent.style.height = "auto";
			}
		}

		calculateMainContentHeight();

		window.addEventListener("resize", calculateMainContentHeight);
	}
});